import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'irembogov-custom-report-filters',
  templateUrl: './custom-report-filters.component.html',
  styleUrls: ['./custom-report-filters.component.scss'],
})
export class CustomReportFiltersComponent implements OnInit {
  @Input() filter: any;
  @Input() reportCodes?: string[];

  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  perfChosenDate?: Date;
  monthInputCtrl: FormControl = new FormControl(
    new Date(this.currentYear, this.currentMonth - 1, 1)
  );

  @Output() filterChange = new EventEmitter<any>();

  ngOnInit(): void {
    this.monthInputCtrl.valueChanges.subscribe(val => {
      this.filterChange.emit({
        newReportArgs: this.mapEventByFilterType(this.filter?.type, val),
      });
    });
  }

  /**
   * Maps the format of input event based on input type
   * @param filterType the input's filter type
   * @param inputValue the input's value
   * @returns
   */
  mapEventByFilterType(
    filterType: string,
    inputValue: string
  ): Record<string, any> | undefined {
    switch (filterType) {
      case 'MONTH_YEAR_DATE_PICKER':
        return {
          [this.filter?.queryArg]: moment(inputValue).format('YYYY-MM'),
        };
      case 'YEAR_DATE_PICKER':
        return {
          [this.filter?.queryArg]: moment(inputValue).format('YYYY'),
        };
      default:
        break;
    }

    return undefined;
  }
}
