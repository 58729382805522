import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReportContainer } from '../../../models/reporting/report-container.model';
import { ReportComponent } from '../../../models/reporting/report-component.model';

@Component({
  selector: 'irembogov-custom-report-container-item',
  templateUrl: './custom-report-container-item.component.html',
  styleUrls: ['./custom-report-container-item.component.scss'],
})
export class CustomReportContainerItemComponent implements OnChanges {
  @Input() container?: ReportContainer;
  @Input() isDataUpdateInProgress = false;
  @Input() reportsData: any;
  @Output() filterChange = new EventEmitter<any>();

  isLoadingReportItem = false;

  ngOnChanges(_: SimpleChanges) {
    this.isLoadingReportItem = false;
  }

  getContainerQueryCodes(components: ReportComponent[]) {
    return components.map(c => c.queryCode);
  }

  emitFilterChange(onFilterChangeEvent: { newReportArgs: any }) {
    const { newReportArgs } = onFilterChangeEvent;
    this.isLoadingReportItem = true;
    this.filterChange.emit({
      newReportArgs,
      reportCodes: this.getContainerQueryCodes(
        this.container?.components ?? []
      ),
    });
  }
}
