import { Injectable } from '@angular/core';
import { RolesEnum } from '@irembo-andela/irembogov3-common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private role$ = new BehaviorSubject<RolesEnum>(RolesEnum._UNKNOWN_ROLE_);
  currentRole$ = this.role$.asObservable();

  setCurrentRole(role: RolesEnum) {
    this.role$.next(role);
  }
}
