<ng-container *ngIf="isDataAvailable === true">
  <p class="font-weight-bold">{{ name | translate }}</p>
  <div style="height: 430px">
    <canvas
      baseChart
      #baseChart="base-chart"
      [datasets]="lineChartData"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
      type="line"
      [labels]="chartLabels">
    </canvas>
  </div>
</ng-container>
