<ng-container>
  <table class="table table-striped table-sm table-hover">
    <thead class="thead-inverse">
      <tr>
        <th *ngFor="let title of titles" scope="col">{{ title['name'] }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="tableRows?.length! > 0; else noData">
        <tr *ngFor="let tableRow of tableRows">
          <td *ngFor="let column of tableRow">{{ column }}</td>
        </tr>
      </ng-container>
      <ng-template #noData>
        <tr>
          <td [attr.colspan]="titles?.length">No Data Available</td>
        </tr>
      </ng-template>
    </tbody>
    <tfoot class="thead-inverse">
      <tr>
        <th
          *ngFor="let tableFooterItem of tableFooterItems"
          class="text-primary"
          scope="col">
          {{ tableFooterItem }}
        </th>
      </tr>
    </tfoot>
  </table>
</ng-container>
