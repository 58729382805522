import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEnvironment } from '../../models/environment.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private environment: IEnvironment;

  constructor(
    private http: HttpClient,
    @Inject('environment') environment: IEnvironment
  ) {
    this.environment = environment;
    if (!this.environment.apiGatewayBaseUrl) {
      throw new Error(
        'useBaseUrl: portal environment ApiGatewayBaseUrl property is required'
      );
    }
  }

  getReportByReportCode(code: string) {
    const url = `${this.environment.apiGatewayBaseUrl}/reporting/admin/manage/get-report-by-code/${code}`;
    return this.http.get(url);
  }

  generateCustomReport(
    reportCode: string,
    reportScriptCodes: string[],
    args: Record<string, string>
  ): Observable<any> {
    return this.http.post(
      `${this.environment.apiGatewayBaseUrl}/reporting/handler/get-report-data/${reportCode}`,
      {
        reportCodes: reportScriptCodes,
        args,
      }
    );
  }

  getLastUpdatedTime() {
    return this.http.get(
      `${this.environment.apiGatewayBaseUrl}/reporting/solr/last-updated-time`
    );
  }

  downloadReport(reportCode: string, params: Record<string, unknown>) {
    return this.http.post(
      `${this.environment.apiGatewayBaseUrl}/reporting/handler/download-report/${reportCode}`,
      params,
      {
        responseType: 'blob',
        headers: new HttpHeaders().append('Content-Type', 'application/json'),
      }
    );
  }
}
