<div class="material-design">
  <mat-form-field class="date-picker">
    <mat-label>{{ label }}</mat-label>
    <input
      (click)="_openDatepickerOnClick(datepicker)"
      (dateChange)="_dateChangeHandler($event.value!)"
      (dateInput)="_dateChangeHandler($event.value!)"
      (focus)="_openDatepickerOnFocus(datepicker)"
      [formControl]="_inputCtrl"
      [matDatepicker]="datepicker"
      [matDatepickerFilter]="_customFilter!"
      [max]="_max!"
      [min]="_min!"
      matInput
      readonly />
  </mat-form-field>
  <mat-datepicker #datepicker [touchUi]="touchUi"></mat-datepicker>
</div>
