import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IAccountActivationRequest } from '../models/account-activation-request.model';
import { IAccountActivationResponse } from '../models/account-activation-response.model';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { RouterStateSnapshot } from '@angular/router';
import { IPasswordResetRequest } from '../models/password-reset-request.model';
import { IPasswordResetResponse } from '../models/password-reset-response.model';
import { IPasswordResetTokenRequest } from '../models/password-reset-token-request.model';
import { IPasswordResetTokenResponse } from '../models/password-reset-token-response.model';
import { IResendActivationTokenRequest } from '../models/resend-activation-token-request.model';
import { UserType } from '../models/user-profile-model';
import { IGetProfileResponse } from '../models/profile-response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private keycloak: KeycloakService) {}

  activateAccount(request: IAccountActivationRequest) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/activate-account`;
    return this.http.post<IAccountActivationResponse>(url, request, {
      headers,
    });
  }

  logout() {
    this.keycloak.logout();
  }

  isLoggedIn() {
    return this.keycloak.isLoggedIn();
  }

  getInstance() {
    return this.keycloak.getToken();
  }

  userProfile(): Promise<KeycloakProfile> {
    return this.keycloak.loadUserProfile();
  }

  userRoles(): string[] {
    return this.keycloak.getUserRoles();
  }

  redirectToLogin(state: RouterStateSnapshot) {
    return this.keycloak.login({
      redirectUri: window.location.origin + state.url,
    });
  }

  requestPasswordToken(request: IPasswordResetTokenRequest) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/reset-password/send-token`;
    return this.http.post<IPasswordResetTokenResponse>(url, request, {
      headers,
    });
  }

  resetPassword(request: IPasswordResetRequest) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/reset-password`;
    return this.http.put<IPasswordResetResponse>(url, request, { headers });
  }

  resendActivationToken(request: IResendActivationTokenRequest) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/resend-activation-token`;
    return this.http.post<Record<string, unknown>>(url, request, { headers });
  }

  checkActivationToken(oneTimePassword: string, username: string) {
    const headers = new HttpHeaders({
      username,
      oneTimePassword,
      userType: UserType.OTHER,
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/account-activation/check-token-validity`;
    return this.http.get<Record<string, unknown>>(url, { headers });
  }

  verifyOneTimePassword(oneTimePassword: string, username: string) {
    const headers = new HttpHeaders({
      oneTimePassword: window.atob(oneTimePassword),
      username: window.atob(username),
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/verify-one-time-password`;
    return this.http.get<Record<string, unknown>>(url, { headers });
  }

  resendActivationTokenUsingExpiredToken(token: string, username: string) {
    const request = {
      token,
      username,
      clientId: environment.authClientId,
      userType: UserType.OTHER,
    };
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/account-activation/resend-token`;
    return this.http.post<Record<string, unknown>>(url, request);
  }

  getUserDetailsById(id: string) {
    const headers: HttpHeaders = new HttpHeaders({
      userId: id,
      userType: UserType.OTHER,
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/user-by-id`;
    return this.http.get<IGetProfileResponse>(url, { headers });
  }

  logoutUser() {
    this.keycloak.logout();
  }
}
