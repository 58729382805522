import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import moment from 'moment';

@Component({
  selector: 'irembogov-custom-report-line-chart',
  templateUrl: './custom-report-line-chart.component.html',
  styleUrls: ['./custom-report-line-chart.component.scss'],
})
export class CustomReportLineChartComponent implements OnChanges {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() name = '';
  @Input() legends: any[] = [];
  @Input() colors: string[] = [];
  @Input() data: any[] = [];
  @Input() xAxisFormat: any;
  @Input() yAxisFormat: any;
  @Input() resultFormat: any;
  @Input() resultType = '';
  chartLabels: string[] = [];
  chartColors: string[] = [];
  // lineChartData: ChartDataset[] = [];
  lineChartData: any[] = [];
  isDataAvailable = false;
  // Define colors of chart segments
  lineChartColors: string[] = [];
  // Set true to show legends
  lineChartLegend = true;
  // Define chart options
  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
  };

  ngOnChanges(changes: SimpleChanges) {
    this.isDataAvailable = false;
    const chartData = [];

    if (changes['colors']?.currentValue) {
      this.lineChartColors = changes['colors'].currentValue.map(
        (c: string) => ({
          borderColor: c,
        })
      );
    }

    if (changes['legends']?.currentValue) {
      this.legends = changes['legends']?.currentValue;
    }

    if (changes['data']?.currentValue) {
      this.chartLabels =
        changes['data']?.currentValue?.map(
          (labelObj: { name: string; val: string }) => {
            let label;
            // Getting the label if not null
            // When using SQL, response is {name: string}
            if (labelObj.name) label = labelObj.name;
            // When using SOLR, response is {val: string}
            if (labelObj.val) label = labelObj.val;

            // Apply formatting of xAxis if not null
            if (this.xAxisFormat?.datePattern) {
              label = moment(label).format(this.xAxisFormat?.datePattern);
            }
            return label;
          }
        ) || [];
    }

    for (let i = 0; i < this.legends?.length; i++) {
      const legend = this.legends[i];
      const dataArr: any[] = changes['data']?.currentValue;
      // Map data from array by index of label
      const mappedData = dataArr.map(dataItem => {
        const temp = { ...dataItem };
        delete temp.name; // remove SQL label
        delete temp.count; // remove SOLR count
        delete temp.val; // remove SOLR label
        return this.getObjValueByIndex(temp, i, this.resultType);
      });

      chartData.push({
        label: legend?.name || legend,
        data: mappedData,
        fill: false,
      });
    }
    this.lineChartData = chartData;
    this.isDataAvailable = true;
  }

  getObjValueByIndex(
    obj: Record<any, any>,
    index: number,
    resultType: string
  ): string | number {
    const key = this.legends[index]?.key;
    if (resultType === 'PERCENT') {
      return (obj['subTotal']?.count * 100) / obj['total'] || 0;
    } else {
      if (typeof obj[key] === 'object') {
        return obj[key]?.result || 0;
      } else {
        return obj[key] || 0;
      }
    }
  }
}
