import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  officerRoles: Record<string, string> = environment.officerRoles;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isAuthorized(route, state);
  }

  async isAuthorized(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const isLoggedIn = await this.authService.isLoggedIn();
    if (isLoggedIn) {
      const roles: string[] = this.authService.userRoles();
      const allowedRoles = roles
        .map(role => this.officerRoles[role])
        .filter(role => role !== undefined);

      if (allowedRoles.length) {
        return true;
      } else {
        this.authService.logoutUser();
        return false;
      }
    }
    await this.authService.redirectToLogin(state);
    return false;
  }
}
