import { environment } from '../environments/environment';
import { AuthService } from './core/services/auth.service';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import {
  INavbarItem,
  ToastService,
  RolesEnum,
} from '@irembo-andela/irembogov3-common';
import {
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { IGetProfileResponse } from './core/models/profile-response.model';
import IIremboAgentProfile from './core/models/user-profile-model';
import { SharedDataService } from './core/services/shared-data.service';
import { IIremboOrganization } from './core/models/irembo-organization.model';
import {
  ITopNavbarItem,
  _ITopNavbarItems,
} from './core/models/navbar-sidebar/top-navbar-items.mpdel';
import { RoleService } from './core/services/role.service';
import {
  ISidebarItem,
  _SidebarItems,
} from './core/models/navbar-sidebar/sidebar-items.model';
import { UsersService } from './core/services/users.service';
import { IUserResponse } from './core/models/users-list.model';
import { AdministrationService } from './core/services/administration.service';
import { KeycloakService } from 'keycloak-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './core/services/locale.service';
import { ILocale } from './core/models/locale/locale.model';

@Component({
  selector: 'irembogov-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  adminEngineFileManagerFileBaseUrl = `${environment.apiGatewayBaseUrl}/admin/v1/file-manager?fileName=`;
  userProfile: KeycloakProfile | undefined;
  userAccount: IIremboAgentProfile | undefined;
  isLoggedIn = false;
  navbarItems: INavbarItem[] = [];
  organisationItems: Record<string, unknown>[] = [];
  organisations: IIremboOrganization[] = [];
  sideMenuItems: ISidebarItem[] = [];
  officerRoles: Record<string, string> = environment.officerRoles;
  event$: Subscription;
  selectedOrg: IIremboOrganization | undefined;
  currentRole?: string;
  userRoles: string[] = [];
  activeLocale: string;
  activeLocaleName: string;
  supportedLocales: ILocale[] = [];

  @ViewChild('logoutModalContent')
  logoutModalContent!: ElementRef;

  constructor(
    private authService: AuthService,
    private keycloakService: KeycloakService,
    private router: Router,
    public sharedDataService: SharedDataService,
    public roleService: RoleService,
    private userService: UsersService,
    private toastService: ToastService,
    private adminService: AdministrationService,
    private modalService: NgbModal,
    public translate: TranslateService,
    private localeService: LocaleService
  ) {
    this.activeLocale =
      localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
    this.activeLocaleName =
      localStorage.getItem('localeName') ?? environment.DEFAULT_LOCALE_NAME;
    translate.addLangs([this.activeLocale]);
    translate.setDefaultLang(this.activeLocale);
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.generateSideMenu(event.url);
      }
    });

    this.event$.add(
      this.sharedDataService.selectedOrganisation$.subscribe(
        (value: Record<string, unknown>) => {
          this.selectedOrg = value?.['org'] as IIremboOrganization;
        }
      )
    );
  }

  async ngOnInit(): Promise<void> {
    this.getSupportedLocales();
    this.isLoggedIn = await this.authService.isLoggedIn();

    this.roleService.currentRole$.subscribe(role => {
      if (role !== this.currentRole) {
        this.currentRole = role;
        this.generateMenu();

        this.setInstituionsByRole();
      }
    });

    if (this.roles.length > 0) {
      this.roleChange(this.roles[0]);
    }

    this.keycloakService.getUserRoles().forEach(element => {
      if (Object.keys(environment.officerRoles).includes(element)) {
        this.userRoles.push(element);
      }

      if (this.userRoles.length > 0) {
        this.currentRole = this.userRoles[0];
      }

      if (this.currentRole === 'ROLE_OFFICER') {
        this.router.navigate(['/dashboard/services/applications']);
      } else {
        this.router.navigate(['/dashboard/users/offices']);
      }
    });

    this.keycloakService.loadUserProfile().then(value => {
      this.userProfile = value;
    });
  }

  localeChanged(locale: ILocale) {
    this.translate.use(locale.locale);
    localStorage.setItem('locale', locale.locale);
    localStorage.setItem('localeName', locale.nativeName);
    this.activeLocale = locale.locale;
    this.activeLocaleName = locale.nativeName;
  }

  getSupportedLocales() {
    this.localeService.getSupportedLocales().subscribe({
      next: response => {
        this.supportedLocales = response.data.content;
        this.translate.addLangs(this.supportedLocales.map(lang => lang.locale));
      },
      error: error => {
        this.toastService.show({
          body: error.error.responseMessage ?? error.error.message,
          type: 'error',
          delay: 5000,
        });
      },
    });
  }

  getLocaleIcon(locale: string): string {
    let flagCode = locale.includes('-') ? locale.split('-')[1] : locale;
    if (flagCode === 'US') {
      flagCode = 'GB';
    }
    return getUnicodeFlagIcon(flagCode);
  }

  changeLocale(item: ILocale) {
    this.activeLocale = item.locale;
    this.activeLocaleName = item.nativeName;
    localStorage.setItem('locale', item.locale);
    localStorage.setItem('localeName', item.nativeName);
    this.translate.use(item.locale);
  }

  async setInstituionsByRole() {
    const userId: string =
      (await this.keycloakService.loadUserProfile()).id ?? '';

    this.getUserAccount(userId);
    this.getOfficerUSerBytId(userId);
  }

  roleChange($event: string) {
    this.roleService.setCurrentRole(this.getRoleByName($event));
  }

  getRoleByName(name: string): RolesEnum {
    let role = RolesEnum._UNKNOWN_ROLE_;
    Object.keys(environment.officerRoles).forEach(key => {
      if (
        environment.officerRoles[
          key as keyof typeof environment.officerRoles
        ] === name
      ) {
        role = key as RolesEnum;
      }
    });

    return role;
  }

  get roles() {
    this.officerRoles = environment.officerRoles;
    return this.authService
      .userRoles()
      .map(role => this.officerRoles[role])
      .filter(role => role !== undefined);
  }

  async handleLogout() {
    const confirmDeactivate: boolean = await this.openConfirmationModal(
      this.logoutModalContent
    );

    if (!confirmDeactivate) {
      return;
    }

    this.authService.logout();
  }

  logout() {
    this.modalService.dismissAll();
    this.authService.logout();
  }

  openConfirmationModal(content: any): Promise<boolean> {
    return this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        modalDialogClass: 'logoutModalDialog',
        windowClass: 'logoutModalWindow',
        backdropClass: 'logoutModalBackdrop',
      })
      .result.then(result => {
        if (result.reasons) {
          return true;
        }
        return false;
      })
      .catch(() => false);
  }

  handleSelectOrg() {
    // ToDo: Filter applications based on selected service.
  }

  generateMenu() {
    const navbarItems: ITopNavbarItem[] = [];
    Object.keys(_ITopNavbarItems).forEach(key => {
      if (
        _ITopNavbarItems[key].allowedRoles?.includes(
          this.currentRole as RolesEnum
        )
      ) {
        navbarItems.push(_ITopNavbarItems[key]);
      }
    });

    this.navbarItems = navbarItems;
  }

  generateSideMenu(currentUrl: string) {
    const items: ISidebarItem[] = [];
    _SidebarItems.forEach(item => {
      if (
        currentUrl.includes(item.group) &&
        item.allowedRoles.includes(this.currentRole as RolesEnum)
      ) {
        items.push(item);
      }
    });

    this.sideMenuItems = items;
  }

  getUserAccount(id: string) {
    this.authService.getUserDetailsById(id).subscribe({
      next: (payload: IGetProfileResponse) => {
        const orgIds: string[] = [];
        this.userAccount = payload.data;
        this.userAccount.userRoles.forEach(value => {
          if (value.scopeType === 'ORGANIZATION' && value.scopeId) {
            if (!orgIds.includes(value.scopeId)) {
              orgIds.push(value.scopeId);
            }
          }
        });
        this.loadOrganisationsAndAddToMenu(orgIds);
      },
      error: err => {
        // Better error handling needed to be done
        console.error('Error:', err);
      },
    });
  }

  getOfficerUSerBytId(userId: string) {
    this.userService.getUserByUserId(userId).subscribe({
      next: (response: IUserResponse) => {
        this.sharedDataService.setOffices(response.data?.offices ?? []);
      },
      error: err => {
        this.toastService.show({
          body: err.error.message ?? err.error.responseMessage,
          delay: 5000,
          type: 'error',
        });
      },
    });
  }

  ngOnDestroy() {
    this.event$.unsubscribe();
  }

  private loadOrganisationsAndAddToMenu(orgIds: string[]) {
    let organizations: IIremboOrganization[] = [];
    orgIds.forEach(organisationId => {
      this.adminService.getOrganizationById(organisationId).subscribe({
        next: response => {
          organizations = [...organizations, response.data];

          organizations.forEach(element => {
            element.organizationId = element.id;
          });

          this.organisations = organizations;

          this.addMenuItems();
        },
        error: err => {
          console.error('Error:', err);
        },
      });
    });
  }

  private addMenuItems() {
    this.organisations = [...new Set(this.organisations.map(obj => obj))];
    this.organisationItems = [];
    this.organisations.forEach(value => {
      this.organisationItems.push({
        value: value.id,
        org: value,
        text: value.acronym,
        icon_image_link:
          value.logo === ''
            ? undefined
            : this.adminEngineFileManagerFileBaseUrl + value.logo,
      });
    });

    //select first item
    this.sharedDataService.setOrganisation(this.organisationItems[0]);
    this.sharedDataService.setOrganizations(this.organisationItems);
  }
}
