<div class="card main-wrapper-card mb-3 mt-2">
  <div *ngIf="isLoadingReportItem" class="loading-cover">
    <div class="loading-dim-cover"></div>
    <i
      style="top: 50%; left: 50%"
      class="ld ld-hourglass ld-spin s-spinner"></i>
  </div>
  <div class="card-body content-wrapper-card-body">
    <h3
      *ngIf="container?.title"
      class="card-subtitle px-4 py-4 text-black font-weight-bold">
      {{ container?.title ?? '' | translate }}
    </h3>
    <div class="card-body content-wrapper-card-inner">
      <div class="d-flex flex-row-reverse">
        <ng-container *ngFor="let filter of container?.filters">
          <irembogov-custom-report-filters
            [filter]="filter"
            [reportCodes]="getContainerQueryCodes(container?.components!)"
            (filterChange)="
              emitFilterChange($event)
            "></irembogov-custom-report-filters>
        </ng-container>
      </div>
      <span class="row" *ngIf="!isDataUpdateInProgress">
        <ng-container *ngFor="let component of container?.components">
          <ng-container *ngIf="component?.type === 'STAT_CARD'">
            <div class="{{ component?.gridSize || 'col-3' }}">
              <irembogov-custom-report-statistics-card
                label="{{ component?.name }}"
                [value]="
                  component?.queryCode &&
                  reportsData?.[component?.queryCode!]?.data?.result
                "
                suffix="{{ component?.showMetric ? component?.metric : '' }}"
                hint="{{ component?.showHint ? component?.hint : '' }}"
                valueDifference="{{ component?.subValue?.label }}"
                differenceLabel="{{ component?.subValue?.label }}"
                resultType="{{ component?.resultType }}"
                [resultFormat]="
                  component?.format?.result
                "></irembogov-custom-report-statistics-card>
            </div>
          </ng-container>
          <ng-container *ngIf="component?.type === 'LINE_CHART'">
            <div class="{{ component?.gridSize || 'col-md-6' }}">
              <irembogov-custom-report-line-chart
                [name]="component?.name!"
                [legends]="component?.labels!"
                [colors]="component?.colors!"
                [data]="
                  (component?.queryCode &&
                    reportsData?.[component?.queryCode!]?.data?.result) ||
                  []
                "
                [title]="component?.name"
                [xAxisFormat]="component?.format?.xAxis"
                [yAxisFormat]="component?.format?.yAxis"
                [resultFormat]="component?.format?.result"
                resultType="{{
                  component?.resultType
                }}"></irembogov-custom-report-line-chart>
            </div>
          </ng-container>
          <ng-container *ngIf="component?.type === 'HISTOGRAM'">
            <div class="{{ component?.gridSize || 'col-md-6' }}">
              <irembogov-custom-report-histogram
                [name]="component?.name!"
                [legends]="component?.labels!"
                [colors]="component?.colors!"
                [data]="
                  (component?.queryCode &&
                    reportsData?.[component?.queryCode!]?.data?.result) ||
                  []
                "
                [title]="component?.name"
                [xAxisFormat]="component?.format?.xAxis"
                [yAxisFormat]="component?.format?.yAxis"
                [showXAxisTitle]="component?.axisLabel?.['xAxis']?.display"
                [xAxisTitle]="component?.axisLabel?.['xAxis']?.text"
                [showYAxisTitle]="component?.axisLabel?.['yAxis']?.display"
                [yAxisTitle]="component?.axisLabel?.['yAxis']?.text"
                [resultFormat]="component?.format?.result"
                [renderAsSingleBarchart]="component?.renderAsSingleBarchart"
                resultType="{{
                  component?.resultType
                }}"></irembogov-custom-report-histogram>
            </div>
          </ng-container>
          <ng-container *ngIf="component?.type === 'DATA_TABLE'">
            <irembogov-custom-report-table
              [titles]="component?.titles!"
              [data]="
                reportsData?.[component?.queryCode!]?.data?.result || []
              "></irembogov-custom-report-table>
          </ng-container>
        </ng-container>
      </span>
    </div>
  </div>
</div>
