import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {
  UiModule,
  ClientDetailsInterceptor,
  SatHeaderInterceptor,
  initializeKeycloak,
} from '@irembo-andela/irembogov3-common';
import { AppRoutingModule } from './app-routing.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SideMenuComponent } from './components/shared/side-menu/side-menu.component';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { OfficersPerformanceComponent } from './components/officers-performance/officers-performance.component';
import { AddHeaderInterceptor } from './core/interceptors/add-header-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpLoaderFactory,
  SharedModule,
} from './modules/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnauthorizedComponent,
    SideMenuComponent,
    OfficersPerformanceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    AppRoutingModule,
    UiModule,
    FormsModule,
    HttpClientModule,
    NgbToastModule,
    NgbModule,
    TranslateModule.forRoot({
      defaultLanguage:
        localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    SharedModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (keycloakService: KeycloakService) => {
        return initializeKeycloak(keycloakService, {
          url: environment.authServiceUrl,
          realm: environment.authRealm,
          clientId: environment.authClientId,
          redirectUri: environment.authSuccessRedirectUrl,
        });
      },
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientDetailsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SatHeaderInterceptor,
      multi: true,
    },
    {
      provide: 'environment',
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
