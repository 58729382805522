export interface UserRole {
  role: string;
  scopeType?: string;
  scopeId?: string;
}
export default interface IIremboAgentProfile {
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  id: string;
  lastName: string;
  userRoles: UserRole[];
  username: string;
  profilePicture: IIremboProfile;
}

export interface IIremboProfile {
  contentType: string;
  file: Array<string>;
}

export enum UserType {
  OTHER = 'OTHER',
  CITIZEN = 'CITIZEN',
}
