import { INewUser } from '../models/new-user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { apis } from '../../app-apis';
import { IUserResponse, IUsersList } from '../models/users-list.model';
import { IListQueryParams } from '../models/list-query-params.model';
import {
  convertNestObject,
  convertObject,
} from '../helpers/query-param-converter';
import { Observable } from 'rxjs';
import { OfficerRole } from '../models/officer-role.enum';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private baseUrl = environment.apiGatewayBaseUrl + '/officer';
  private usersAPIs = apis.user;

  constructor(private http: HttpClient) {}

  getUsers(listFilters: IListQueryParams) {
    const params = convertNestObject(listFilters);
    return this.http.get<IUsersList>(
      `${this.baseUrl}${this.usersAPIs.users}?${params}`
    );
  }

  assignOffice(ids: { userId: string; officeId: string }) {
    const params = convertObject(ids);
    return this.http.put(
      `${this.baseUrl}${this.usersAPIs.userAssign}?${params}`,
      {}
    );
  }

  unAssignOffice(ids: {
    userId: string;
    officeId: string;
    organizationId: string;
  }) {
    const params = convertObject(ids);
    return this.http.put(
      `${this.baseUrl}${this.usersAPIs.userUnassign}?${params}`,
      {}
    );
  }

  createUser(newUser: INewUser) {
    return this.http.post(`${this.baseUrl}${this.usersAPIs.user}`, newUser);
  }

  loadUserByUserAndRole(keys: {
    role: OfficerRole;
    userId: string;
    organizationId: string;
  }): Observable<IUserResponse> {
    const params = convertObject(keys);
    return this.http.get<IUserResponse>(
      `${this.baseUrl}${this.usersAPIs.byUserAndRole}?${params}`
    );
  }

  unAssignUserRole(keys: {
    organizationId: string;
    role: OfficerRole;
    userId: string;
  }) {
    const params = convertObject(keys);
    return this.http.put<IUserResponse>(
      `${this.baseUrl}${this.usersAPIs.unAssignRoleFromUser}?${params}`,
      {}
    );
  }

  assignUserRole(keys: {
    organizationId: string;
    role: OfficerRole;
    userId: string;
  }) {
    const params = convertObject(keys);
    return this.http.put<IUserResponse>(
      `${this.baseUrl}${this.usersAPIs.assignRoleFromUser}?${params}`,
      {}
    );
  }

  getUserByUserId(userId: string) {
    return this.http.get<IUserResponse>(
      `${this.baseUrl}${this.usersAPIs.user}?userId=${userId}`,
      {}
    );
  }
}
