<div class="material-design">
  <mat-form-field class="month-picker">
    <mat-label>{{ label }}</mat-label>
    <input
      (click)="_openDatepickerOnClick(datepicker)"
      (focus)="_openDatepickerOnFocus(datepicker)"
      [formControl]="_inputCtrl"
      [matDatepicker]="datepicker"
      [matDatepickerFilter]="_customFilter!"
      [max]="_max!"
      [min]="_min!"
      matInput
      readonly />
  </mat-form-field>
  <mat-datepicker
    #datepicker
    (monthSelected)="_monthSelectedHandler($event, datepicker)"
    (yearSelected)="_yearSelectedHandler($event, datepicker)"
    [touchUi]="touchUi"
    panelClass="month-picker"
    startView="multi-year">
  </mat-datepicker>
</div>
