import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import moment from 'moment';

export function startAndEndTimePickerPairValidator(
  startControlKey: string,
  endControlKey: string,
  startValueToDisableEndControl?: string
): ValidatorFn {
  return (pairFormGroupControl: AbstractControl): ValidationErrors | null => {
    const startControl: AbstractControl<any> | null =
      pairFormGroupControl.get(startControlKey);
    const endControl: AbstractControl<any> | null =
      pairFormGroupControl.get(endControlKey);

    if (startControl?.value || endControl?.value) {
      startControl?.markAsDirty();
      endControl?.markAsDirty();
    }

    if (
      startValueToDisableEndControl &&
      startControl?.value === startValueToDisableEndControl
    ) {
      endControl?.setValue(null, { onlySelf: true, emitEvent: false });
      endControl?.removeValidators(Validators.required);
      endControl?.disable({ emitEvent: false, onlySelf: true });
      endControl?.setErrors(null);
      startControl?.setErrors(null);
      return null;
    } else {
      endControl?.addValidators(Validators.required);
      endControl?.enable({ emitEvent: false, onlySelf: true });
    }

    const startTimeMoment = moment(`${startControl?.value}`, 'HH:mm');
    const endTimeMoment = moment(`${endControl?.value}`, 'HH:mm');

    if (
      endControl?.value &&
      startControl?.value &&
      endTimeMoment.unix() <= startTimeMoment.unix()
    ) {
      endControl?.setErrors({
        ...endControl?.errors,
        endTimeLessThanStartTime: pairFormGroupControl.value,
      });
      return { endTimeLessThanStartTime: pairFormGroupControl.value };
    }

    if (endControl?.errors) {
      delete endControl?.errors['endTimeLessThanStartTime'];
    }

    return null;
  };
}
