import { ISidebarItem } from '../../../core/models/navbar-sidebar/sidebar-items.model';
import { SharedDataService } from '../../../core/services/shared-data.service';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'irembogov-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnInit {
  @Input() menuItems: ISidebarItem[] = [];
  @Input() organisations: Record<string, unknown>[] = [];
  @Input() selectedOrg: number | string | undefined;

  userRoles: string[] | undefined;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    this.sharedDataService.organisations$.subscribe(
      organisations => (this.organisations = organisations)
    );
    this.sharedDataService.selectedOrganisation$.subscribe(organization => {
      if (organization) {
        this.selectedOrg = organization['value'] as string;
      }
    });
    this.sharedDataService.setOrganisation(
      this.getSelectedObject(this.selectedOrg)
    );
  }

  onSelectOrg(value: unknown) {
    this.sharedDataService.setOrganisation(this.getSelectedObject(value));
  }

  private getSelectedObject(value: unknown) {
    return this.organisations.filter(item => item['value'] === value)[0];
  }
}
