<div class="material-design">
  <mat-form-field class="year-picker">
    <mat-label>{{ label }}</mat-label>
    <input
      (click)="_openDatepickerOnClick(datepicker)"
      (focus)="_openDatepickerOnFocus(datepicker)"
      [formControl]="_inputCtrl"
      [matDatepicker]="datepicker"
      [max]="_max"
      [min]="_min"
      matInput
      readonly />

    <mat-datepicker
      #datepicker
      (yearSelected)="_yearSelectedHandler($event, datepicker)"
      [touchUi]="touchUi"
      panelClass="year-picker"
      startView="multi-year">
    </mat-datepicker>
  </mat-form-field>
</div>
