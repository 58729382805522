import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'irembogov-custom-report-table',
  templateUrl: './custom-report-table.component.html',
  styleUrls: ['./custom-report-table.component.scss'],
})
export class CustomReportTableComponent implements OnInit {
  @Input() titles: Record<string, string>[] = [];
  @Input() data: Record<string, string>[] = [];
  tableRows: any[][] = [];
  tableFooterItems: any[] = [];

  ngOnInit(): void {
    const columns = this.titles.map(t => t['key']);
    const footer: any = []; // Language data
    this.data.forEach(d => {
      const row = this.mapValuesToColumns(d, columns);

      row.forEach((rowItem, idx) => {
        if (typeof rowItem == 'number') {
          footer[idx] =
            footer[idx] === undefined
              ? Number(rowItem)
              : footer[idx] + Number(rowItem);
        }
      });

      this.tableFooterItems = footer;
      this.tableRows.push(row);
    });
  }

  /**
   * Maps an object's property to its column by the prop name
   * @param item The object
   * @param columns the list of column names
   * @returns the mapped values to the columns
   */
  mapValuesToColumns(item: Record<string, any>, columns: string[]): string[] {
    const row: any[] = [];
    columns.forEach(column => {
      if (typeof item[column] === 'object') {
        row.push(item[column]?.result || 0);
      } else row.push(item[column] || 0);
    });
    return row;
  }
}
