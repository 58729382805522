import { IListQueryParams } from '../models/list-query-params.model';

/**
 * Converts object to query string parameters
 */
export function convertObject(params: Record<string, unknown>) {
  const queryStringParams = Object.keys(params)
    .filter(
      key =>
        params[key] !== undefined &&
        params[key] !== null &&
        String(params[key]).trim() !== ''
    )
    .map(key => `${key}=${encodeURIComponent(params[key] as string)}`)
    .join('&');
  return queryStringParams;
}

/**
 * Converts object to query string parameters
 */
export function convertNestObject(
  params: IListQueryParams | Record<string, unknown>
) {
  let data: Record<string, unknown> = {};
  for (const [, value] of Object.entries(params)) {
    data = { ...data, ...value };
  }
  const queryStringParams = convertObject(data);
  return queryStringParams;
}
