export const environment = {
  production: false,
  authServiceUrl: 'https://hub-keycloak.uat.irembolabs.com',
  authRealm: 'irembo',
  authClientId: 'officers-portal',
  authSuccessRedirectUrl: 'https://officer-portal.uat.irembolabs.com',
  officerRoles: {
    ROLE_OFFICER_ADMIN: 'Officer Admin',
    ROLE_OFFICER_MANAGER: 'Officer Manager',
    ROLE_OFFICER: 'Officer',
  },
  apiGatewayBaseUrl: 'https://officer-gateway.uat.irembolabs.com',
  allowUploadPreviousFiles: false,
  DEFAULT_LOCALE: 'en-US',
  DEFAULT_LOCALE_NAME: 'English',
  translationSourceUrl: 'https://officer-gateway.uat.irembolabs.com',
};
