<ng-container *ngIf="isDataAvailable === true">
  <p *ngIf="showTitle" class="font-weight-bold">{{ name | translate }}</p>
  <div style="height: 430px">
    <canvas
      baseChart
      [datasets]="barChartData"
      [labels]="chartLabels"
      [options]="barChartOptions"
      [legend]="showLegend"
      type="bar">
    </canvas>
  </div>
</ng-container>
