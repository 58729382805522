import { RolesEnum, INavbarItem } from '@irembo-andela/irembogov3-common';
export interface ITopNavbarItem extends INavbarItem {
  allowedRoles?: RolesEnum[];
  exactRouteAsActive: boolean;
  iconContent?: string;
}

export const _ITopNavbarItems: Record<string, ITopNavbarItem> = {
  Offices: {
    name: 'Offices',
    routerLink: ['/dashboard/users/offices'],
    icon: 'fas fa-university',
    exactRouteAsActive: false,
    allowedRoles: [
      RolesEnum.ROLE_OFFICER_ADMIN,
      RolesEnum.ROLE_OFFICER_MANAGER,
    ],
  },
  Users: {
    name: 'Users',
    routerLink: ['/dashboard/users/list'],
    icon: 'fas fa-users',
    exactRouteAsActive: false,
    allowedRoles: [
      RolesEnum.ROLE_OFFICER_ADMIN,
      RolesEnum.ROLE_OFFICER_MANAGER,
    ],
  },
  Applications: {
    name: 'Applications',
    routerLink: ['/dashboard/services/applications'],
    icon: 'fas fa-list',
    exactRouteAsActive: false,
    allowedRoles: [RolesEnum.ROLE_OFFICER],
  },
  Reporting: {
    name: 'Reports',
    routerLink: ['/dashboard/reporting'],
    icon: 'material-icons-round',
    iconContent: 'bar_chart',
    exactRouteAsActive: false,
    allowedRoles: [
      RolesEnum.ROLE_OFFICER_ADMIN,
      RolesEnum.ROLE_OFFICER_MANAGER,
      RolesEnum.ROLE_OFFICER,
    ],
  },
};
