import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateFilterFn, MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { EPeriod } from '../../../../models/reporting/period.model';

const moment = _rollupMoment || _moment;

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'irembogov-regular-datepicker',
  templateUrl: './regular-date-picker.component.html',
  styleUrls: ['./regular-date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegularDatePickerComponent),
      multi: true,
    },
  ],
})
export class RegularDatePickerComponent implements ControlValueAccessor {
  /** Component label */
  @Input() label = '';
  @Input() touchUi = false;
  _customFilter?: DateFilterFn<Moment | null | undefined>;
  @ViewChild(MatDatepicker, { static: false }) _picker?: MatDatepicker<Moment>;
  _inputCtrl: FormControl = new FormControl();

  _max?: Moment;

  @Input() get max(): Date | undefined {
    return this._max ? this._max.toDate() : undefined;
  }

  set max(max: Date | undefined) {
    if (max) {
      const momentDate = moment(max);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }

  _min?: Moment;

  @Input() get min(): Date | undefined {
    return this._min ? this._min.toDate() : undefined;
  }

  set min(min: Date | undefined) {
    if (min) {
      const momentDate = moment(min);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  private _mode: EPeriod.WEEK | EPeriod.SEMESTER | '' | null = '';

  @Input() get mode(): EPeriod.WEEK | EPeriod.SEMESTER | '' | null {
    return this._mode;
  }

  set mode(mode: EPeriod.WEEK | EPeriod.SEMESTER | '' | null) {
    this._mode = mode;
    this._setupFilter();
  }

  // Function to call when the date changes.
  onChange = (date: Date) => {
    console.log(date);
  };

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {
    console.log('Touched');
  };

  writeValue(date: Date): void {
    if (date) {
      const momentDate = moment(date);
      if (momentDate.isValid()) {
        this._inputCtrl.setValue(moment(date), { emitEvent: false });
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this._picker!.disabled = isDisabled;

    isDisabled ? this._inputCtrl.disable() : this._inputCtrl.enable();
  }

  _dateChangeHandler(chosenDate: Moment) {
    this.onChange(chosenDate.toDate());
    this.onTouched();
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      datepicker.open();
      this.onTouched();
    }
  }

  _openDatepickerOnFocus(datepicker: MatDatepicker<Moment>) {
    setTimeout(() => {
      if (!datepicker.opened) {
        datepicker.open();
        this.onTouched();
      }
    });
  }

  private _setupFilter() {
    switch (this.mode) {
      case EPeriod.WEEK:
        this._customFilter = (d: Moment | null | undefined) => {
          return !d?.day();
        };
        break;
    }
  }
}
