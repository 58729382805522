import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '../../../services/reporting/reports.service';

@Component({
  selector: 'irembogov-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent implements OnInit {
  lastUpdatedTime?: string;
  @Input() showLastUpdatedTime = false;

  constructor(private reportsService: ReportService) {}

  ngOnInit(): void {
    this.reportsService.getLastUpdatedTime().subscribe({
      next: (res: Record<string, any>) =>
        (this.lastUpdatedTime = res['data']?.value),
      error: err => console.error('Error: ', err),
    });
  }
}
