<ng-container>
  <ng-container *ngIf="filter?.type === 'MONTH_YEAR_DATE_PICKER'">
    <irembogov-reporting-custom-date-picker
      [formControl]="monthInputCtrl"
      [max]="currentMonth + '/' + currentYear"
      [label]="'Choose a month' | translate"
      mode="MONTH">
    </irembogov-reporting-custom-date-picker>
  </ng-container>
  <ng-container *ngIf="filter?.type === 'YEAR_DATE_PICKER'">
    <irembogov-reporting-custom-date-picker
      [formControl]="monthInputCtrl"
      [max]="currentMonth + '/' + currentYear"
      [label]="'Choose a year' | translate"
      mode="YEAR">
    </irembogov-reporting-custom-date-picker>
  </ng-container>
</ng-container>
