import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs';
import { ReportService } from '../../../services/reporting/reports.service';
import { ReportData } from '../../../models/reporting/reports-data.model';
import { ReportContainer } from '../../../models/reporting/report-container.model';
import { ReportComponent } from '../../../models/reporting/report-component.model';

@Component({
  selector: 'irembogov-custom-report-container',
  templateUrl: './custom-report-container.component.html',
  styleUrls: ['./custom-report-container.component.scss'],
})
export class CustomReportContainerComponent implements OnInit {
  @Input() reportDefinition?: ReportContainer[];
  @Input() reportArgs?: Record<string, string>;
  @Input() reportDefinitionCode?: string;

  isLoadingReports = true;
  isDataUpdateInProgress = false;
  reportContainers: ReportContainer[] = [];
  reportsData?: Record<string, ReportData>;
  queryCodes = new Set<string>();

  constructor(private reportService: ReportService) {}

  ngOnInit(): void {
    this.reportContainers = this.reportDefinition!;
    this.reportContainers.forEach(reportContainer => {
      this.getValidQueryCodes(reportContainer.components).forEach(c =>
        this.queryCodes.add(c)
      );
    });
    this.getReportsData(this.queryCodes, this.reportArgs!);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { reportArgs, reportDefinition } = changes || {};

    if (!reportArgs?.firstChange) {
      this.getReportsData(this.queryCodes, this.reportArgs!);
    }

    if (!reportDefinition?.firstChange && reportDefinition?.currentValue) {
      this.reportContainers = reportDefinition?.currentValue;
      this.reportContainers.forEach(reportContainer => {
        this.getValidQueryCodes(reportContainer.components).forEach(c =>
          this.queryCodes.add(c)
        );
      });
      this.getReportsData(this.queryCodes, this.reportArgs!);
    }
  }

  /**
   * Gets query data by list of query codes and other arguments
   * @param queryCodes list of query codes used for querying data
   */
  getReportsData(queryCodes: Set<string>, reportArgs: Record<string, any>) {
    this.isLoadingReports = true;
    this.reportsData = undefined;
    if (this.isReportArgValuesValid(reportArgs)) return;
    this.reportService
      .generateCustomReport(
        this.reportDefinitionCode ?? '',
        Array.from(queryCodes),
        reportArgs
      )
      .pipe(
        finalize(() => {
          this.isLoadingReports = false;
        })
      )
      .subscribe({
        next: res => {
          this.reportsData = res?.data?.data;
        },
        error: err => {
          console.error('An Error Occured: ', err);
        },
      });
  }

  /**
   * Gets valid unique list of query codes from component list
   * @param components list of component objects
   * @returns list valid unique query codes
   */
  getValidQueryCodes(components: ReportComponent[]): Set<string> {
    return new Set(
      components
        .filter(
          component => !!component?.queryCode || component?.queryCode !== ''
        )
        .map(component => component?.queryCode)
    );
  }

  /**
   * Makes a request to get data
   * corresponding to query changed
   * @param event
   */
  updateReportData(event: {
    reportCodes: string[];
    newReportArgs: Record<string, any>;
  }): void {
    const { reportCodes, newReportArgs } = event;
    this.reportArgs = { ...this.reportArgs, ...newReportArgs };
    if (this.isReportArgValuesValid(this.reportArgs)) return;
    this.isDataUpdateInProgress = true;
    this.reportService
      .generateCustomReport(
        this.reportDefinitionCode ?? '',
        Array.from(reportCodes),
        this.reportArgs
      )
      .pipe(finalize(() => (this.isDataUpdateInProgress = false)))
      .subscribe({
        next: res => {
          this.reportsData = { ...this.reportsData, ...res?.data.data };
        },
        error: err => console.error('An Error Occurred: ', err),
      });
  }

  isReportArgValuesValid(reportArgs: Record<string, any>) {
    return Object.values(reportArgs || []).some(
      reportArgVal =>
        (Array.isArray(reportArgVal) && reportArgVal?.length <= 0) ||
        reportArgVal === undefined
    );
  }
}
