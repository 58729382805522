<div class="top-menu">
  <i class="fa-solid bull-dot">&bull;</i>
  <irembogov-select
    placeholder=""
    [items]="organisations"
    [bindLabel]="'text'"
    [bindValue]="'value'"
    [multiple]="false"
    [hideIcon]="true"
    [(ngModel)]="selectedOrg"
    [showCancel]="false"
    appendTo=".top-menu"
    (ngModelChange)="onSelectOrg(selectedOrg)"></irembogov-select>
</div>
