import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IOffice } from '../models/offices-list.model';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private organisation$ = new BehaviorSubject<Record<string, unknown>>({});
  selectedOrganisation$ = this.organisation$.asObservable();

  private organisationsList$ = new BehaviorSubject<Record<string, unknown>[]>(
    []
  );

  private userOffices$ = new BehaviorSubject<IOffice[]>([]);

  organisations$ = this.organisationsList$.asObservable();

  offices$ = this.userOffices$.asObservable();

  setOrganisation(organisation: Record<string, unknown>) {
    this.organisation$.next(organisation);
  }

  setOrganizations(organisations: Record<string, unknown>[]) {
    this.organisationsList$.next(organisations);
  }

  setOffices(offices: IOffice[]) {
    this.userOffices$.next(offices);
  }
}
