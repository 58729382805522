import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import moment from 'moment';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { IIrembogovBasicLabelKeyPair } from '../../models/irembogov-basic-label-keypair.model';

@Component({
  selector: 'irembogov-time-value-picker',
  templateUrl: './irembo-time-value-picker.component.html',
  styleUrls: ['./irembo-time-value-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboTimeValuePickerComponent),
      multi: true,
    },
  ],
})
export class IremboTimeValuePickerComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Output() itemSelected = new EventEmitter<string | null>();
  @Input() prependNoneTimeItem:
    | IIrembogovBasicLabelKeyPair<string>
    | undefined = undefined;
  @Input() itemIconPosition: 'prepend-icon' | 'append-icon' = 'prepend-icon';
  @Input() placeholder = '';
  @Input() startHour = 1;
  @Input() disabled = false;
  @Input() endHour = 23;
  @Input() interval = 15;
  @Input() customFormControl = new FormControl();
  @Input() labelForId: unknown;
  timeSlots: Record<string, string>[] = [];
  $subject = new Subject<void>();
  @Input() appendTo = 'body';
  @Input() includeNextMidnight = true;

  ngOnInit(): void {
    this.customFormControl.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => {
          return a === b;
        }),
        takeUntil(this.$subject)
      )
      .subscribe((value: unknown) => {
        this.changedValue(value);
      });
    this.generateTimeValueSlots(this.startHour, this.endHour, this.interval);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.disabled = changes?.['disabled']?.currentValue ?? false;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onTouch = (value: unknown) => {};
  private _onChange = (value: unknown) => {};
  /* eslint-enable */

  changedValue(obj: unknown): void {
    const value = obj ? <string>obj : null;
    this._onChange(value);
    this._onTouch(value);
    this.itemSelected.emit(value);
  }

  writeValue(value: unknown): void {
    this.customFormControl.setValue(value, {
      emitEvent: false,
      onlySelf: true,
    });
  }
  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  generateTimeValueSlots(startHour: number, endHour: number, interval: number) {
    if (
      interval > 60 ||
      interval < 0 ||
      startHour > endHour ||
      startHour < 0 ||
      endHour > 24
    ) {
      throw new Error(
        'Invalid start hour, end hour or interval parameter configured'
      );
    }

    if (this.prependNoneTimeItem?.key && this.prependNoneTimeItem.label) {
      this.timeSlots.push({
        value: this.prependNoneTimeItem?.key,
        label: this.prependNoneTimeItem?.label,
      });
    }

    const momentStartHour = moment(`${startHour}:00`, 'HH:mm');
    const momentEndHour = moment(`${endHour}:00`, 'HH:mm');

    const operator = this.includeNextMidnight ? '<=' : '<';

    while (
      operator === '<='
        ? momentStartHour <= momentEndHour
        : momentStartHour < momentEndHour
    ) {
      const momentLabelValue = momentStartHour.format('hh:mm A');
      const isMidnight =
        endHour === 24 &&
        moment.duration(momentEndHour.diff(momentStartHour)).asHours() === 0;
      this.timeSlots.push({
        value: isMidnight ? '24:00' : momentStartHour.format('HH:mm'),
        label: `${momentLabelValue}${isMidnight ? '*' : ''}`,
      });
      momentStartHour.add(interval, 'minutes');
    }
  }

  ngOnDestroy(): void {
    this.$subject.next();
    this.$subject.complete();
  }
}
