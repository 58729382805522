<ng-container>
  <div class="stat-card rounded border p-4">
    <div class="">
      <p class="card-text">
        {{ label ?? '' | translate
        }}<sup
          class="stat-label-hint"
          *ngIf="hint"
          tooltipClass="report-stat-card-tooltip"
          [ngbTooltip]="hint | translate"
          >&#9432;</sup
        >
      </p>
      <h1 class="card-text stat-value">{{ processedValue }}{{ suffix }}</h1>
    </div>
    <div *ngIf="valueDifference" class="card-difference-area">
      <div class="{{ differenceColor }}">
        <span class="card-text"
          >{{ valueDifference }}{{ differenceSuffix }}</span
        >
        <!-- <i-feather name="{{ differenceIcon }}" class="small-icon"></i-feather> -->
      </div>
      <span class="card-label">{{ differenceLabel ?? '' | translate }}</span>
    </div>
  </div>
</ng-container>
