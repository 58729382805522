export const apis = {
  office: {
    createOffice: '/v1/create-office',
    offices: '/v1/offices',
    office: '/v1/office',
    locations: '/v1/locations',
    unassign: '/v1/user-unassign',
    assign: '/v1/user-assign',
    assignManager: '/v1/assign-officer-manager-office',
    assignOfficer: '/v1/assign-officer-office',
    unAssignManager: '/v1/unassign-officer-manager-office',
    unAssignOfficer: '/v1/unassign-officer-office',
    organization: '/v1/organization',
    organizationByUserId: '/v1/organization-by-user-id',
  },
  location: {
    locations: '/v1/locations',
  },
  account: {
    createOfficer: '/v1/create-officer',
    createOfficerManager: '/v1/create-officer-manager',
    usersByRole: '/v1/users-by-role',
    users: '/v1/all-users',
  },
  user: {
    users: '/v1/users',
    user: '/v1/user',
    userActivate: '/v1/user-activate',
    byUserAndRole: '/v1/user/by-user-and-role',
    userAssign: '/v1/user-assign',
    userDeactivate: '/v1/user-deactivate',
    userUnassign: '/unassign-officer-manager-office',
    unAssignRoleFromUser: '/v1/user/unassign-user-role',
    assignRoleFromUser: '/v1/user/assign-user-role',
  },
  officeWorkHours: {
    getOrganizationWorkHours: '/v1/office-work-hours/organization',
    updateOrganizationWorkHours: '/v1/office-work-hours/organization',
    officeWorkHoursEntityBaseEndpoint: '/v1/office-work-hours/office',
  },
  holiday: {
    recurring: {
      org: '/v1/office-recurring-holidays/organization',
      office: '/v1/office-recurring-holidays/office',
    },
    custom: {
      org: '/v1/office-custom-holidays/organization',
      office: '/v1/office-custom-holidays/office',
    },
  },
};
