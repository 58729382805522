<irembogov-year-picker
  *ngIf="mode === EPeriod.YEAR"
  [formControl]="_yearPickerCtrl"
  [label]="label"
  [max]="max"
  [min]="min"
  [touchUi]="touchUi">
</irembogov-year-picker>

<irembogov-month-picker
  *ngIf="_showMonthPicker"
  [formControl]="_monthPickerCtrl"
  [label]="label"
  [max]="max"
  [min]="min"
  [mode]="mode"
  [touchUi]="touchUi">
</irembogov-month-picker>

<irembogov-regular-datepicker
  *ngIf="_showRegularDatepicker"
  [formControl]="_regularPickerCtrl"
  [label]="label"
  [max]="max"
  [min]="min"
  [mode]="mode"
  [touchUi]="touchUi">
</irembogov-regular-datepicker>
