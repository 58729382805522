import { RolesEnum } from '@irembo-andela/irembogov3-common';

export interface ISidebarItem {
  link: string;
  quaryParams?: any;
  text: string;
  icon: string | undefined;
  group: string;
  allowedRoles: RolesEnum[];
}

export const _SidebarItems: ISidebarItem[] = [
  {
    link: '/dashboard/reporting/officer-admin-reports',
    text: 'Offices Performance',
    icon: 'fa fa-building',
    group: 'reporting',
    allowedRoles: [RolesEnum.ROLE_OFFICER_ADMIN],
    quaryParams: {
      reportCode: 'OFFICE_PERFORMANCE',
    },
  },
  {
    link: '/dashboard/reporting/officer-admin-reports',
    text: 'Application Traffic',
    icon: 'fa fa-building',
    group: 'reporting',
    allowedRoles: [RolesEnum.ROLE_OFFICER_ADMIN],
    quaryParams: {
      reportCode: 'OFFICE_APPLICATION_TRAFFIC',
    },
  },
  {
    link: '/dashboard/users/list',
    text: 'Users',
    icon: 'fa fa-users',
    group: 'users',
    allowedRoles: [RolesEnum.ROLE_OFFICER_ADMIN],
  },
  {
    link: '/dashboard/users/list',
    text: 'Officers',
    icon: 'fa fa-users',
    group: 'users',
    allowedRoles: [RolesEnum.ROLE_OFFICER_MANAGER],
  },
  {
    link: '/dashboard/users/offices',
    text: 'Offices',
    icon: 'fa fa-building',
    group: 'users',
    allowedRoles: [
      RolesEnum.ROLE_OFFICER_ADMIN,
      RolesEnum.ROLE_OFFICER_MANAGER,
    ],
  },
  {
    link: '/dashboard/services/applications',
    text: 'Applications',
    icon: 'fa fa-list-alt',
    group: 'services',
    allowedRoles: [RolesEnum.ROLE_OFFICER],
  },
  {
    link: '/dashboard/services/applications',
    text: 'Assigned to me',
    icon: 'fa-solid fa-calendar-days',
    group: 'services',
    allowedRoles: [RolesEnum.ROLE_OFFICER],
    quaryParams: {
      assigned: true,
    },
  },
  {
    link: '/dashboard/reporting/officer-reports',
    text: 'My Performance',
    icon: undefined,
    group: 'reporting',
    allowedRoles: [RolesEnum.ROLE_OFFICER],
    quaryParams: {
      reportCode: 'OFFICER_PERFORMANCE',
    },
  },
  {
    link: '/dashboard/reporting/officer-reports',
    text: 'Pending Applications',
    icon: undefined,
    group: 'reporting',
    allowedRoles: [RolesEnum.ROLE_OFFICER],
    quaryParams: {
      reportCode: 'OFFICER_PENDING_APPLICATIONS',
    },
  },
];
