<ng-template [ngIf]="isLoggedIn" [ngIfElse]="public">
  <header>
    <irembogov-navbar
      [navbarItems]="navbarItems"
      [navbarWide]="false"
      [showNotification]="false"
      [showActiveRole]="true"
      [activeRole]="officerRoles[currentRole!]"
      [ngClass]="'fixed-top'">
      <irembogov-side-menu
        [menuItems]="sideMenuItems"
        [selectedOrg]="selectedOrg?.id"
        (newSelectEvent)="handleSelectOrg()"
        orgSelector></irembogov-side-menu>

      <div
        class="locale-selector"
        customSection
        *ngIf="currentRole === 'ROLE_OFFICER'">
        <div ngbDropdown class="px-2">
          <div class="me-4" ngbDropdownToggle role="button">
            <span class="me-2 locale-emoji rounded-circle">{{
              getLocaleIcon(activeLocale)
            }}</span>
            <span class="active-locale">{{ activeLocaleName }}</span>
          </div>
          <ul
            class="dropdown-menu dropdown-menu-end"
            ngbDropdownMenu
            aria-labelledby="userProfile_dd">
            <li class="" *ngFor="let item of supportedLocales">
              <a
                ngbDropdownItem
                class="dropdown-item cursor-pointer"
                (click)="changeLocale(item)">
                <div class="d-flex flex-row justify-content-between">
                  <div class="me-4 locale-name">
                    <span class="me-2 locale-emoji">{{
                      getLocaleIcon(item.locale)
                    }}</span>
                    {{ item.nativeName }}
                  </div>

                  <div>
                    <i
                      class="fa-regular"
                      [ngClass]="{
                        'fa-circle-check locale-checked-color':
                          activeLocale === item.locale,
                        'fa-circle locale-unchecked-color':
                          activeLocale !== item.locale
                      }"></i>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <irembogov-navbar-profile-section
        [username]="
          this.userProfile?.firstName + ' ' + this.userProfile?.lastName
        "
        [activeRole]="officerRoles[currentRole!]"
        [roles]="roles"
        (logout)="handleLogout()"
        (roleChange)="roleChange($event)"
        [showUserDetails]="false"
        [showDropdownEmail]="false"
        [showDropdownRoles]="false"
        [showDropdownUserDetails]="true"
        [showProfileLink]="false"
        [profileLinkText]="'Edit Profile' | translate"
        profile></irembogov-navbar-profile-section>
    </irembogov-navbar>
  </header>
  <div class="wrapper container">
    <div id="main">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>

<ng-template #public>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #logoutModalContent let-modal>
  <div class="modal-header p-3 border-0" style="align-items: start">
    <div class="d-flex align-items-top justify-content-start">
      <div class="modal-title-icon-warning icon-border-light me-4">
        <i class="fa-solid fa-sign-out"></i>
      </div>
      <div class="heading-text">
        <h5 class="modal-title fw-bolder">{{ 'Logout' | translate }}</h5>
        <p>{{ 'Do you want to logout?' | translate }}</p>
      </div>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-footer d-flex justify-content-end border-0">
    <button
      type="button"
      class="btn btn-outline-dark btn-lg"
      (click)="modal.dismiss('Close click')">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button" class="btn btn-danger btn-lg" (click)="logout()">
      {{ 'Logout' | translate }}
    </button>
  </div>
</ng-template>

<irembogov-toast aria-live="polite" aria-atomic="true"></irembogov-toast>
