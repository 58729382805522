<ng-container>
  <div
    *ngIf="isLoadingReports || isDataUpdateInProgress; else showCharts"
    class="d-flex align-items-center justify-content-center container-loader">
    <i class="fa fa-hourglass fa-3x fa-spin"></i>
  </div>
  <ng-template #showCharts>
    <div *ngFor="let container of reportContainers">
      <irembogov-custom-report-container-item
        [container]="container"
        [reportsData]="reportsData"
        [isDataUpdateInProgress]="isDataUpdateInProgress"
        (filterChange)="
          updateReportData($event)
        "></irembogov-custom-report-container-item>
    </div>
  </ng-template>
</ng-container>
