import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { EPeriod } from '../../../models/reporting/period.model';

@Component({
  selector: 'irembogov-reporting-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomReportingDatePickerComponent),
      multi: true,
    },
  ],
})
export class CustomReportingDatePickerComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @Input() mode: any;
  @Input() label = '';
  @Input() max: any;
  @Input() min: any;
  @Input() touchUi = false;

  _yearPickerCtrl: FormControl = new FormControl();
  _monthPickerCtrl: FormControl = new FormControl();
  _regularPickerCtrl: FormControl = new FormControl();

  private _onDestroy: Subject<void> = new Subject<void>();

  get _showMonthPicker(): boolean {
    return (
      this.mode === EPeriod.MONTH ||
      this.mode === EPeriod.MONTHYEAR ||
      this.mode === EPeriod.SEMESTER
    );
  }

  get _showRegularDatepicker(): boolean {
    return !this.mode || this.mode === EPeriod.WEEK;
  }

  get EPeriod() {
    return EPeriod;
  }

  ngAfterViewInit() {
    switch (this.mode) {
      case EPeriod.YEAR:
        this._subscribeToChanges(this._yearPickerCtrl);
        break;
      case EPeriod.MONTH:
      case EPeriod.SEMESTER:
      case EPeriod.MONTHYEAR:
        this._subscribeToChanges(this._monthPickerCtrl);
        break;
      default:
        this._subscribeToChanges(this._regularPickerCtrl);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
  }

  onChange = (date: Date) => {
    console.log(date);
  };

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {
    console.log('Touched');
  };

  writeValue(date: any): void {
    if (date) {
      this._writeValue(date);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    switch (this.mode) {
      case EPeriod.YEAR:
        isDisabled
          ? this._yearPickerCtrl.disable()
          : this._yearPickerCtrl.enable();
        break;
      case EPeriod.MONTH:
      case EPeriod.SEMESTER:
      case EPeriod.MONTHYEAR:
        isDisabled
          ? this._monthPickerCtrl.disable()
          : this._monthPickerCtrl.enable();
        break;
      default:
        isDisabled
          ? this._regularPickerCtrl.disable()
          : this._regularPickerCtrl.enable();
    }
  }

  private _writeValue(date: any): any {
    if (!date) {
      return;
    }

    switch (this.mode) {
      case EPeriod.YEAR:
        if (date instanceof Date) {
          this._yearPickerCtrl.setValue(date, { emitEvent: false });
        }
        break;
      case EPeriod.MONTHYEAR:
      case EPeriod.MONTH:
      case EPeriod.SEMESTER:
        if (date instanceof Date) {
          this._monthPickerCtrl.setValue(date, { emitEvent: false });
        }
        break;
      default:
        if (date instanceof Date) {
          this._regularPickerCtrl.setValue(date);
        }
        break;
    }
  }

  private _parseMonthAndYear(date: string) {
    const dashIndex = date.indexOf('-');
    const forwardSlashIndex = date.indexOf('/');
    if (dashIndex === -1 && forwardSlashIndex === -1) {
      return;
    }

    if (forwardSlashIndex > -1) {
      return this._getValidStringMonth(date.split('/'));
    }

    if (dashIndex > -1) {
      return this._getValidStringMonth(date.split('-'));
    }

    return undefined;
  }

  private _parseYear(date: string) {
    const month = Number(date);
    if (isNaN(month)) {
      return;
    }

    return month;
  }

  private _getValidStringMonth(splittedDate: string[]) {
    if (splittedDate.length !== 2) {
      return;
    }

    const month = Number(splittedDate[0]);
    if (isNaN(month) || month > 11 || month < 0) {
      return;
    }

    const year = Number(splittedDate[1]);
    if (isNaN(year) || year < 0) {
      return;
    }

    // Notice we are converting month to human convention, 1..12.
    // This is done because of the way month-picker is building up
    // the moment object using MM/YYYY format instead of and array.
    return month + 1 + '/' + year;
  }

  private _subscribeToChanges(control: FormControl) {
    if (!control) {
      return;
    }
    control.valueChanges.subscribe(value => {
      const date = new Date(value.toString());
      this.onChange(date);
      this.onTouched();
    });
  }
}
