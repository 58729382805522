import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IIremboOrganizationResponse } from '../models/irembo-organization.model';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  private authBaseUrl = environment.apiGatewayBaseUrl + '/admin';

  constructor(private http: HttpClient) {}

  getOrganizationById(organizationId: string) {
    const url = `${this.authBaseUrl}/v1/organizations/${organizationId}`;
    return this.http.get<IIremboOrganizationResponse>(url, {});
  }
}
