import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputErrorComponent } from './input-error/input-error.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TimeHhMmToAmPmPipe } from '../../core/pipes/time-hh-mm-to-am-pm-pipe';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineToastComponent } from './in-line-toast/in-line-toast.component';
import { DueDateStatusPipe } from '../../core/pipes/due-date-status.pipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomLoader(http);
}
export class CustomLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const apiAddress = `${environment.translationSourceUrl}/admin/v1/translations/static-text/by-locale/${lang}`;
    return this.httpClient.get(apiAddress, { headers: header });
  }
}

@NgModule({
  declarations: [
    InputErrorComponent,
    InlineToastComponent,
    TimeHhMmToAmPmPipe,
    DueDateStatusPipe,
  ],
  imports: [
    CommonModule,
    NgbToastModule,
    TranslateModule.forChild({
      defaultLanguage:
        localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    InputErrorComponent,
    InlineToastComponent,
    TimeHhMmToAmPmPipe,
    TranslateModule,
    DueDateStatusPipe,
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
    };
  }
}
