<div id="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="alert alert-info">
          <i class="fa fa-info-circle" aria-hidden="true"></i> You not
          authorized to access this page.
        </div>
        <button type="button" class="btn btn-primary" (click)="logoutUser()">
          Go to login page
        </button>
      </div>
    </div>
  </div>
</div>
