import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'irembogov-custom-report-statistics-card',
  templateUrl: './custom-report-statistics-card.component.html',
  styleUrls: ['./custom-report-statistics-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomReportStatisticsCardComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() value: any;
  @Input() suffix?: string;
  @Input() hint?: string;
  @Input() valueDifference?: string;
  @Input() differenceSuffix?: string;
  @Input() differenceIcon?: string;
  @Input() differenceColor?: string;
  @Input() differenceLabel?: string;
  @Input() resultType?: string;
  @Input() resultFormat: any;
  processedValue?: string;

  ngOnInit(): void {
    this.processedValue = this.getProcessedValue(
      this.value,
      this.resultType ?? '',
      this.resultFormat
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resultType']?.currentValue) {
      this.resultType = changes['resultType']?.currentValue;
    }

    if (changes['resultFormat']?.currentValue) {
      this.resultFormat = changes['resultFormat']?.currentValue;
    }

    if (changes['value']?.currentValue) {
      this.value = changes['value']?.currentValue;
    }
    this.getProcessedValue(
      changes['value']?.currentValue,
      this.resultType ?? '',
      this.resultFormat
    );
  }

  getProcessedValue(value: any, resultType: string, resultFormat: any) {
    let val: number = this.processValueByResultType(value, resultType) ?? 0;
    val = parseFloat(val?.toFixed(resultFormat?.floatingPoint || 0));
    return val.toString();
  }

  processValueByResultType(value: any, resultType: string) {
    if (value === null) return 0;
    if (resultType === 'PERCENT') {
      if (value?.subTotal?.count && value?.total) {
        return (value?.subTotal?.count * 100) / value?.total;
      }
      return 0;
    } else {
      if (value[0] !== undefined) return value[0];
      if (value !== undefined) return value;
      return 0;
    }
  }
}
