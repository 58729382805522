import { AuthService } from './../../core/services/auth.service';
import { Component } from '@angular/core';

@Component({
  selector: 'irembogov-unauthorized',
  templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent {
  constructor(private authService: AuthService) {}

  logoutUser() {
    this.authService.logout();
  }
}
